import { Injectable, OnDestroy } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, DocumentReference } from '@angular/fire/firestore';
import { environment } from '@env';
import firebase from 'firebase/app';
import { Subscription } from 'rxjs';

export enum DB_ACTIONS {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  LINK = 'LINK ACCOUNT',
  AGREE_TERMS = 'Login Success',
  LOGIN_ATTEMPT = 'Login Attempt',
  LOGIN_SUCCESS = 'Login Success',
  LOGIN_SUCCESS_NO_EMAIL = 'Login Success Email Not Verified',
  LOGIN_FAILURE = 'Login Failure',
  LOGOUT_USER = 'Logout User',
  PASSWORD_RESET = 'Password Reset',
  DELETE = 'DELETE',
  READ = 'READ',
  CRIMINAL_SEALED = 'CRIMINAL_SEALED',
  ERROR = 'ERROR',
  EFILE = 'EFILE',
  ERECORD = 'ERECORD',
  INDEX_PRINT = 'INDEX PRINT',
  CIVIL_DOCKET = 'CIVIL_DOCKET',
  INDEX_IMAGE_ERROR = 'IMAGE ERROR',
}

const AUDIT_COLLECTION = environment.aconfig.AUDIT;

export interface Log {
  id?: string;
  v?: string;
  action: DB_ACTIONS;
  user?: string;
  date?: Date;
  collection?: string;
  itemId?: string;
  object?: any;
  clerkNumber?: string;
  details?: string;
}


@Injectable({
  providedIn: 'root',
})
export class AuditService implements OnDestroy {
  public currentUser: firebase.User | null;
  protected _sub: Subscription;

  constructor(protected _afs: AngularFirestore, protected _authService: AngularFireAuth) {
    this._sub = this._authService.authState.subscribe((user) => (this.currentUser = user));
  }

  ngOnDestroy(): void {
    this._sub.unsubscribe();
  }

  public logEvent(log: Log): Promise<void> {
    const now = new Date();
    log.id = `${now.getUTCFullYear()}-${(now.getUTCMonth() + 1).toString().padStart(2, '0')}-${(now.getUTCDate() + 1)
      .toString()
      .padStart(2, '0')} ${(now.getUTCHours() + 1).toString().padStart(2, '0')}:${(now.getUTCMinutes() + 1)
      .toString()
      .padStart(2, '0')}:${(now.getUTCSeconds() + 1).toString().padStart(2, '0')}.${(now.getUTCMilliseconds() + 1)
      .toString()
      .padStart(2, '0')}`;
    log.user = this.currentUser?.email || 'system';
    log.v = '3.0960';
    log.date = log.date || new Date();
    return this._afs.doc(`${AUDIT_COLLECTION}/${log.id}`).set(log);
  }

  public logError(log: Log): Promise<void> {
    const now = new Date();
    log.id = `${now.getUTCFullYear()}-${(now.getUTCMonth() + 1).toString().padStart(2, '0')}-${(now.getUTCDate() + 1)
      .toString()
      .padStart(2, '0')} ${(now.getUTCHours() + 1).toString().padStart(2, '0')}:${(now.getUTCMinutes() + 1)
      .toString()
      .padStart(2, '0')}:${(now.getUTCSeconds() + 1).toString().padStart(2, '0')}.${(now.getUTCMilliseconds() + 1)
      .toString()
      .padStart(2, '0')}`;
    log.user = this.currentUser?.email || 'system';
    log.v = '7';
    log.date = log.date || new Date();
    return this._afs.doc(`${AUDIT_COLLECTION}/${log.id}`).set(log);
  }

  public deleteEventById(id: string): Promise<void> {
    return this._afs.doc(`${AUDIT_COLLECTION}/${id}`).delete();
  }

  public deleteEventByRef(ref: DocumentReference): Promise<void> {
    return ref.delete();
  }
}
