export const environment = {
  production: true,
  info: {
    publicUrl: 'https://search.desotoparishclerk.org/login',
    district: '42ND',
    city: 'MANSFIELD',
    parish: 'DESOTO',
    url: 'search.desotoparishclerk.org',
    logo: 'clerk_seal_desoto.png',
    clerkName: 'LISA LOBRANO BURSON',
    clerkAddress: 'PO BOX 1206',
    clerkCityStateZip: 'MANSFIELD, LA  71052',
    clerkPhone: '(318) 872-3110',
    clerkWeb: 'www.desotoparishclerk.org',
    email: 'RECORDING@DESOTOPARISHCLERK.ORG',
    civilFees: 'https://desotoparishclerk.org/divisions/civil-probate/',
    videoUrl: 'https://firebasestorage.googleapis.com/v0/b/cn26rec_pub/o/videos%2FClerkNet_Search_Options.mp4?alt=media&token=8accd570-7280-4109-bad5-a5a28e6b2072'
  },
  civilFees: {
    subpoenas: 75,
  },
  dates: {
    civil: 689239681000,
    criminal: 1420081885000,
    conveyances: -378608272000,
    mortgages: -36297347200,
    recording: -362991472000,
    marriages: -1956531472000,
  },
  recaptcha: {
    siteKey: '6LdFpAEfAAAAADa2twAC3dHIEm_21UPUT4IGr4ea'
  },
  mconfig: {
    url: 'https://cnms.desotoparishclerk.org',
    apiKey: 'f145cf0d25cf997de3ce07d9d31d65df23e6e1f440b53b963c003a50cf3b003e',
    criminal: 'desotoCriminal',
    civil: 'desotoCivil',
  },
  sb: {
    databaseurl: 'https://ocmhzrgnzahomvfqutkb.supabase.co',
    databasekey: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Im9jbWh6cmduemFob212ZnF1dGtiIiwicm9sZSI6ImFub24iLCJpYXQiOjE2ODcyOTgxMTksImV4cCI6MjAwMjg3NDExOX0.jRtbll0vDYVX_g8B7P9OJy4HrJ1wQWBPzwRcZgUtazo',
    i: 'recordinginstruments',
    n: 'recordingnames',
    url: 'https://ocmhzrgnzahomvfqutkb.supabase.co',
    key: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Im9jbWh6cmduemFob212ZnF1dGtiIiwicm9sZSI6ImFub24iLCJpYXQiOjE2ODcyOTgxMTksImV4cCI6MjAwMjg3NDExOX0.jRtbll0vDYVX_g8B7P9OJy4HrJ1wQWBPzwRcZgUtazo'
  },
  aconfig: {
    appId: 'RZYQ3P4JBS',
    apiKey: '18f2496e8324ee32d8167b7e51f57e6d',
    CIVIL: 'desotoCivil',
    RECORDING: 'desotoIndex',
    CRIMINAL: '',
    AUDIT: 'publicAudit',
  },
  afs: {
    civ: 'cn42civil',
    civpub: 'cn42civil_pub',
    civfolder: 'cnCivil',
    crimpub: 'cn42crim_pub',
    recpub: 'cn42rec_pub',
    crimfolder: 'cnCriminal',
    crim: 'cn42criminal',
    recfolder: '',
    rec: 'cn42recording',
    eRecording: 'cn42erecording',
    eFiling: 'cn42efiling',
  },
  firebaseConfig: {
    apiKey: "AIzaSyAUaSgbuJmgnbe885wy4dF2o1eViyoLNr8",
    authDomain: "clerknetdesoto.firebaseapp.com",
    projectId: "clerknetdesoto",
    storageBucket: "clerknetdesoto.appspot.com",
    messagingSenderId: "959665539500",
    appId: "1:959665539500:web:7f3c2cb0f781c260659209",
    measurementId: "G-W11JY0QPWK"
  },
  stripe: {
    plans: [
      {
        name: '24 HOUR',
        rate: 20,
        fee: .91,
        total: 20.91,
        discount: '',
        planId: 'price_1Mzm2WEB0mE5f5OUYEgvfqc7'
      },
      {
        name: '1 MONTH',
        rate: 35,
        fee: 1.32,
        total: 36.35,
        discount: '',
        planIdRenewal: 'price_1MzmB0EB0mE5f5OUZX265cdk',
        planId: 'price_1Mzm3eEB0mE5f5OU1QQ6cklu'
      },
      {
        name: 'YEARLY',
        rate: 350,
        fee: 10.45,
        total: 360.76,
        discount: '',
        planIdRenewal: 'price_1Mzm9zEB0mE5f5OUoQwpd8aQ',
        planId: 'price_1Mzm4bEB0mE5f5OUqiP7IhbV'
      }
    ],
    clerknet: 'pk_live_514ffBuFDbduv4AUe0WNSqOQXa9Ucn7XyYE4gGbHSPTQP9laJYhc0WLAC9pn8psLh7mV6Qq8NS7ta1PzSKUygzKvA00tZ0iBHHF',
    clerknetTest: '',
    civilAcct: 'acct_1MSNh9KC36VuU9Oa',
    recAcct: 'acct_1MPWOHEB0mE5f5OU',
    connect: '',
    recording: 'pk_live_51MPWOHEB0mE5f5OUasdkaa9TvOvz6k1RSrwoltqnFQT9E3qUQ0AWUfcS6WJlEviM7YJ00DT6mf4HKukXj7DC3iAH00INCRrGvQ',
    recordingTest: '',
    civil: 'pk_live_51MSNh9KC36VuU9OaDF0ynQiO5BUipPkX4xwJAMxBdfdrHnewcSyuN5dwgzgMYfyODeo07utAaoc9nZQ9bT5WhYhe00IFNl7Kmh',
    civilTest: ''
  }
};
