import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { environment } from '@env';
import { Observable } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { NotificationService } from '../services/notification.service';

@Injectable({
  providedIn: 'root'
})
export class TermsGuard implements CanActivate {
  constructor(
    public authService: AuthService,
    public router: Router,
    private notify: NotificationService
  ){ }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      return this.authService.user$.pipe(
        take(1),
        map(user => user.public && user.public?.agreeDate >= 1719838358000),
        tap(hasAgreed => {

            // console.log('Subscription Guard', isSubscribed);
            if (!hasAgreed) {
              this.router.navigateByUrl('/login/terms').then(() => {
                this.notify.showConfirmOkButton('UPDATED TERMS', 'WE HAVE UPDATED OUR TERMS - PLEASE REVIEW AND ACCEPT BEFORE GETTING STARTED!');
              });
            } else {
              return hasAgreed;
              // if (environment.info.parish === 'WEBSTER') {
              //   const path = next.routeConfig.path;
              //   if (path === 'civil' || path === 'account') {
              //     return true;
              //   } else {
              //     this.router.navigateByUrl('/app/civil/').then(() => {
              //       this.notify.showConfirmOkButton('ONLY CIVIL ACCESS ALLOWED!', 'AT THIS TIME WE ARE ONLY OFFERING ACCESS TO CIVIL RECORDS!');
              //     });
              //   }
              // }
            }
        })
    );
  }
}

